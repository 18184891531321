<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row>
      <v-col
        cols="12"
        md="auto">
        <h2>Vendors</h2>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-select
          v-model="query.sortBy"
          :items="sortByOptions"
          item-text="text"
          item-value="value"
          label="เรียงจาก"
          outlined
          dense
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <v-select
          v-model="query.sortOrder"
          :items="sortOrderOptions"
          item-text="text"
          item-value="value"
          label="เรียงลำดับ"
          outlined
          dense
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getItems(true)" />
          <v-btn
            color="success"
            :disabled="loading"
            to="/purchases/vendors/create"
            class="ml-2 mb-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            color="success"
            :disabled="loading"
            class="ml-2 mb-1"
            @click="exportCsv()">
            <v-icon>mdi-table-arrow-up</v-icon>
          </v-btn>
          <v-btn
            v-if="isDeveloper"
            color="info"
            :disabled="loading"
            class="ml-2 mb-1"
            @click="syncAllVendor()">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template
            #[`item.bcSync`]="{ item }"
            class="d-flex justify-center">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
                  v-bind="attrs"
                  color="success"
                  v-on="on">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  v-bind="attrs"
                  color="error"
                  v-on="on">
                  mdi-sync-alert
                </v-icon>
              </template>
              <span v-if="item.bcSync && item.bcSync.updatedAt">
                Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
              </span>
              <span v-else>
                Not sync yet
              </span>
            </v-tooltip>
          </template>
          <template #[`item.searchName`]="{ item }">
            {{ item.searchName || '-' }}
          </template>
          <template #[`item.phoneNumber`]="{ item }">
            {{ item.phoneNumber || '-' }}
          </template>
          <template #[`item.email`]="{ item }">
            {{ item.email || '-' }}
          </template>
          <template #[`item.vendorType`]="{ item }">
            {{ mapType(item.vendorType.name) }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/purchases/vendors/${item.id}`">
              <v-icon small>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              small
              icon
              @click="confirmRemoveItem(item)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import SearchBox from '@/components/SearchBox.vue'
import VendorsProvider from '@/resources/VendorsProvider'
import { getRole } from '@/assets/js/Authentication'

const VendorsService = new VendorsProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: 'Sync',
          value: 'bcSync',
          width: 80
        },
        {
          text: 'รหัส',
          value: 'vendorNo',
          sortable: false
        },
        {
          text: 'ชื่อ',
          value: 'name',
          sortable: false
        },
        {
          text: 'ชื่อสำหรับค้นหา',
          value: 'searchName',
          sortable: false
        },
        {
          text: 'เบอร์โทร',
          value: 'phoneNumber',
          sortable: false
        },
        {
          text: 'อีเมล',
          value: 'email',
          sortable: false
        },
        {
          text: 'ประเภท',
          value: 'vendorType',
          sortable: false
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          sortable: false
        }
      ],
      vendorTypes: [
        {
          text: 'นิติบุคคล',
          value: 'juristic'
        },
        {
          text: 'บุคคลธรรมดา',
          value: 'individual'
        }
      ],
      items: [],
      sortByOptions: [
        {
          text: 'รหัส',
          value: 'vendorNo'
        },
        {
          text: 'ชื่อ',
          value: 'name'
        },
        {
          text: 'วันที่สร้าง',
          value: 'createdAt'
        },
        {
          text: 'วันที่แก้ไข',
          value: 'updatedAt'
        }
      ],
      sortOrderOptions: [
        {
          text: 'A-Z',
          value: 'asc'
        },
        {
          text: 'Z-A',
          value: 'desc'
        }
      ],
      query: {
        page: 1,
        limit: 10,
        role: null,
        status: null,
        sortBy: 'vendorNo',
        sortOrder: 'asc',
        search: ''
      },
      pageCount: 0,
      loading: false
    }
  },
  computed: {
    isDeveloper () {
      return getRole() === 'developer'
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'query.sortBy': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.sortOrder': {
      handler () {
        this.getItems(true)
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await VendorsService.getItems({
          page: this.query.page,
          limit: this.query.limit,
          search: this.query.search,
          sortBy: this.query.sortBy,
          sortOrder: this.query.sortOrder
        })

        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    confirmRemoveItem (item) {
      this.setModal({
        value: true,
        title: 'Remove Item',
        message: `Do you want to remove ${item.vendorNo}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeItem(item.id)
      })
    },
    async syncAllVendor () {
      try {
        this.loading = true

        await VendorsService.sync()
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async removeItem (id) {
      try {
        this.loading = true

        await VendorsService.deleteItem(id)

        this.getItems(true)
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    mapType (type) {
      const vendorType = this.vendorTypes.find((vt) => vt.value === type)

      return vendorType?.text || type
    },
    async exportCsv () {
      try {
        this.loading = true

        const { data } = await VendorsService.getItems({
          page: 1,
          limit: 999999,
          search: this.query.search,
          sortBy: this.query.sortBy,
          sortOrder: this.query.sortOrder
        })

        const mapped = data.results.map((item) => ({
          'รหัส': item.vendorNo,
          'ชื่อ': item.name,
          'ชื่อ 2': item.name2,
          'ชื่อสำหรับค้นหา': item.searchName,
          'ที่อยู่': item.address,
          'ที่อยู่ 2': item.address2,
          'จังหวัด': item.city,
          'รหัสไปรษณีย์': item.postalCode,
          'เบอร์โทร': item.phoneNumber,
          'อีเมล': item.email,
          'เลขผู้เสียภาษี': item.taxRegistrationNumber,
          'สาขา': item.branch,
          'ชื่อผู้ติดต่อ': item.contactName,
          'Purchaser Code': item.purchaserCode,
          'รวมภาษี': item.priceIncludesTax ? 'TRUE' : 'FALSE',
          'สกุลเงิน': item.currencyCode,
          'ประเภท': item.vendorType.name,
          'Payment Term': item.paymentTermCode,
          'Vendor Posting Group': item.vendorPostingGroup,
          'Gen. Bus. Posting Group': item.genBusPostingGroup,
          'Vat. Bus. Posting Group': item.vatBusPostingGroup,
          'Wht. Bus. Posting Group': item.whtBusPostingGroup
        }))

        const options = {
          filename: `vendors-${this.$dayjs().format('YYYY-MM-DD')}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(mapped)
      } catch (error) {
        console.error('exportCsv', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
